<template>
  <div style="overflow-y: auto">
    <div class="container mt-2">
      <div class="row" v-for="(row, i) in items" v-bind:key="i">
        <div class="" v-for="item in row" v-bind:key="item.id">
          <Item class="m-2" :item="item" :purchasable="true"></Item>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as mock from '@/mock.js';

export default {
  computed: {
    items() {
      let toRender = [];
      for (let i = 0; i < 100; i++) {
        toRender.push(mock.item());
      }

      const itemsPerRow = 4;
      // Do the 'binning' into groups of 4
      return toRender.reduce((col, item, i) => {
        if (i % itemsPerRow === 0)
          col.push([]); // Add a new row
        
        col[col.length - 1].push(item); // push item to the current row
        return col;
      }, []);
          
    }
  },
  methods: {
   
  }
}
</script>

<style lang="scss" scoped>

</style>